.modal_inner{
    width: 500px;
    padding: 40px 20px;
    border-radius: 20px;
border: 2px solid #FFF;
background: #262626;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.modal_outer{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin:auto;
    top:0;
    left:0;
    height: 100%;
    z-index: 100;
    opacity: 1 !important;
}



.cards_info{
    margin: 5px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
}


.modal_heading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bet1_color{
    color: #000;
}

.modal_heading svg{
    width: 27px;
    margin-left: 10px;
    margin-bottom: -15px;
}

.modal_heading{
    align-items: center;
    display: flex;

}

.modal_heading h2{
font-family: Poppins;
color: #fff;
font-size: 27px;
font-style: normal;
align-items: center;
font-weight: 700;
line-height: normal;
}

.bet1_color{
    color: #4ECB71;
}   

.input_modal input{
    height: 53px;
flex-shrink: 0;
width: 100%;
background: rgba(217, 217, 217, 0.16);
border: 0;
text-decoration: none;
color: #fff;
padding-left: 20px;
}

.placebet_modal{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
border-radius: 50px;
background: #ED6622;
line-height: normal;
width: 100%;
cursor: pointer;
transition: all 0.3s ease-in-out ;
}

.placebet_modal:hover{
    transform: scale(1.03);

}

.cancel_modal:hover{
    transform: scale(1.03);

}

.cancel_modal{
    border-radius: 50px;
    transition: all 0.3s ease-in-out ;
background: #F00;
width: 100%;
text-align: center;
color: #fff;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 600;
border-radius: 50px;
line-height: normal;
margin-top: 10px;
width: 100%;

}

.info_box{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}