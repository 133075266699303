.result_row{
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.transaction_outer{
    width: 90%;
    margin: auto;
    margin-top: 50px !important;
    display: flex;
    justify-content: space-between;
    gap:30px;
    padding-bottom:50px;
}

.result_row:last-child{
    border-bottom: none;
}

.bid_closes{
    color: #fff;
    padding: 50px 0px ;
}

.view{
    cursor: pointer;
    text-decoration: underline;

}

.transaction_row{
    height: 450px;
    overflow-y: scroll;


}

.result_table{
    height: 300px;
    overflow-y: scroll;

}

.claim_outer{
    margin-top: 10px ;
    background: rgba(217, 217, 217, 0.1);
    height: 140px;
    padding: 20px;

}

.label1_claim{
    color: #fff;
}


.button_claim{
    width: 100%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

}


.button_claim:hover{
   transform: scale(1.03);
}
.claim_info{
    display: flex;
    justify-content: space-between;
}

.transaction_row::-webkit-scrollbar{
    display: none;
}

.result_table::-webkit-scrollbar{
    display: none;
}

.transaction_right{
    width: 60%;

}

.transaction_right::-webkit-scrollbar {
    display: none;
  }

.transaction_left::-webkit-scrollbar {
    display: none;
  }
  

.transaction_left{
    width: 40%;


}

.result_box{
    background: rgba(217, 217, 217, 0.1);
    padding: 20px 20px;
    color: #fff;
}

.result_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.winner_rabbit{
    display: flex;
    align-items: center;
    gap:5px;
}

.result_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
}

.result_title{
    text-align: left;
    font-size: 24px;
    font-weight: 600;

}


.transaction_row{
    display: block;
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.Heading_table{
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-weight: 700;
}

.content_table{
    display: flex;
    justify-content: space-between;
    text-align: left;
    border-bottom: 1px solid rgba(255,255,255,0.25);
}

.content_table:last-child{
    border-bottom: none;
}