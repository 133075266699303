.deposit_inner{
    background: rgba(217, 217, 217, 0.1);
        padding-top: 20px;

}


.deposit_outer{
    width: 90%;
    margin: auto;
    margin-top: 40px;
}

.deposit_label{
    margin-top: 20px;
}

.input_deposit{
    background: rgba(255, 255, 255, 0.10);
    border: none;
    width: 80%;
    height: 40px;
    border-radius: 50px;
    margin-top: 10px;
    padding-left: 15px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    color: #fff;

}


.checkbox_container{
    color: #fff;
    padding: 10px;

}

.button_div{
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    gap:10px;
    justify-content: center;
}

.deposit_label{
    color: #fff;
}

.orange{
    color: #ED6622;
}