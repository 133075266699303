.info_outer{
    background: rgba(217, 217, 217, 0.1);
    width: 90%;
    margin: auto;
    padding: 0 20px;
    margin-top: 20px;
}

.gameId{
    text-align: left;
}

.box{
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    padding-right: 50px;
    color: #fff;
}


.orange{
    color:#ED6622;
}


.info_inner{
    display: flex;
    justify-content: space-between;
}

.box:nth-child(3){
    border-right: 0px solid red !important;

}

