@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.mont{
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 600;

}
.buyButton{
  width: 200px;
  position: fixed;
  bottom:30px;
  right: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 2px 2px 25px 0px #FE7122;
  display: none;
}


a{
  text-decoration: none;
}



#root::-webkit-scrollbar{
  display: none;
}


.montweight{
  font-weight: 800;

}

body{
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}
*{
  box-sizing: border-box;

}



button{
  text-decoration: none;
  border-style: none;
}


.ham{
  display: none;
}

body, #root, html{
  background: #121212;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

@media(max-width:850px){
  .left_home{
    width: 100% !important;
  }

  .buyButton{
    display: block;
  }

  .navbarBuy{
    display: none;
  }

  .home_outer{
    flex-direction: column;
  }
  .box{
    padding-right: 0 !important;
  }

  .cards_inner{
    width: 45% !important;
  }

  .cards_outer{
    flex-wrap: wrap;
  }

  .transaction_outer{
    flex-direction: column;

  }
  .transaction_left, .transaction_right{
    width: 100% !important;
  }

  
  .right_home{
    width: auto !important;
    right: 0 !important;
    position: absolute;
  }

  .sidebar_outer{
    width: 80% !important;
    margin: 0px !important;
    margin-left: auto !important;
    background: #121212 !important;
    padding: 15px;
    top:0px !important;
    position: fixed !important;
    right: -100%;
    height: 100vh !important;
    overflow-y: scroll;
    z-index: 4;
  }

  .ham{
    display: relative;
    z-index: 11;

  }

  
  .sendMessage_container{
    width: 100% !important;
    padding: 15px 15px 0px 15px;
    padding-bottom: 0px !important;
    right: -100%;
    bottom: -15px !important;
  }



  .ham{
    position: fixed !important;
    z-index: 10;
    padding: 10px;
    display: block;
    text-align: right;
    right: 30px;
    top:33px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ED6622;
  }

  .button_container{
    flex-direction: column-reverse;
  }

  .wallet_send{
    margin-top: 11px;
  }

  .address, .date{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 10px !important;

  }

  .message_container p{
    font-size: 14px;
  }

  .sendMessage_container input{
    padding: 0 !important;
    text-align: center;
  }

  .ham{
    text-align: right;
  }

  .right_home, .left_home{
    height: 100% !important;
    overflow-y: visible !important;
    width: auto !important;

  }

  .navbar_inner{
    position: fixed;
    padding: 20px 100px 20px 5% !important;
    width: 100% !important;
    background: #121212;
  }

  .connect_div button{
    background: #ED6622;
    padding: 5px 10px !important;
    border-radius: 50px !important;
    height: auto;
    color: #fff;
    font-weight: 600;


}

}

@media (max-width:500px) {
  .info_outer{
    font-size: 14px;
  }

  .box{
    padding: 0px 10px !important;

  }

  .cards_outer{
    gap:10px !important
  }

  .cards_inner {
    width: 90% !important;
    margin: auto !important;
    margin-top: 20px !important;
  }

  .cards_info{
    font-size: 14px;
    margin: 5px;
    padding: 0;
  }

  .cards_title{
    margin:2px;
  }

  .wallet_button_bet{
    width: 90% !important;
    margin-top: 10px;
  }

  .transaction_table{
    overflow-x: scroll;

  }
  .content_table p{
    padding: 0 15px;
  }
  .Heading_table p{
    padding: 0 15px;
    font-weight: 500 !important;
  }

  .Heading_table{
    justify-content: space-between;
  }

  .info_outer, .box{
    padding: 2px !important;
  }


  .navbar_inner{
    position: fixed;
    padding: 20px 90px 20px 5% !important;
    width: 100% !important;
    background: #121212;
  }
  
  .modal_inner{
    width: 80% !important;
    padding: 40px 20px;
    border-radius: 20px;
border: 2px solid #FFF;
background: #262626;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}





  .ham{
    position: fixed !important;
    z-index: 10;
    padding: 10px;
    display: block;
    text-align: right;
    right: 30px;
    top:37px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ED6622;
  }

  .info_inner{
    flex-direction: column !important;
  }

  .box{
    width: 100% !important;
    border-right: 0px !important;
    border-bottom: 1px solid rgba(255,255,255,0.1) ;
  }

  .socials svg{
    width: 30px !important;
    height: auto;
    transition: all 0.3 ease-in-out;
    cursor: pointer;


}


.socials{
  display: flex;
  gap:10px !important;
}

}