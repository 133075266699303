.player_main{
    height: 300px;
    background: rgba(217, 217, 217, 0.1);
    margin: auto;
}

.player_outer{
    width: 90%;
    margin:auto;
    height: 345px;
}


.gameId{
    color: #fff;
    margin-bottom: -35px;
    margin-top: 50px;
    position: relative;
    margin-left: 20px;
    z-index: 1000;
}
