.socials{
    display: flex;
    gap:20px;
    align-items: center;
}

.logo_section_footer{
    width: 30%;
}

.footer_inner{
    display: flex;
    justify-content: space-between;
    width: 90%;
    justify-content: space-between;
}

.footer_outer{
    background: #000;
    padding: 10px;
    z-index: 100;
    position: relative;
}

.logo_section_footer img{
    width: 80px;
}

.socials svg{
    width: 40px;
    height: auto;
    transition: all 0.3 ease-in-out;
    cursor: pointer;


}

.socials svg:hover{
    transform: scale(1.03);
}


.copyright p{
    color: #fff ;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .copyright{
    border-top:1px solid rgba(255,255,255,0.3) ;
  }
  