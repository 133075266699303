.home_outer{
    display: flex;
    justify-content: space-between;
}

.right_home{
    width: 30%;
    height: calc(100vh - 100px) !important;
    overflow-y: scroll;

}


.right_home::-webkit-scrollbar {
    display: none;
  }
.left_home::-webkit-scrollbar {
    display: none;
  }

.left_home{
    width: 70%;
    height: calc(100vh - 100px) !important;
    overflow-y: scroll;

}

.left_home{
    border-right:  1px solid rgba(255, 255, 255, 0.25);
}