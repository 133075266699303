.navbar_inner, .logo_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar_inner{
    padding: 10px 0px;
    width: 90%;
    margin: auto;
}


.navbar_outer{
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    height: 100px;
}

.wallet_container{
    display: flex;
    gap:20px;
}

.navbarBuy{
    color: #000 !important;
    background-color: #fff !important;
    cursor: pointer;
}


.wallet_button{
    background: #ED6622;
    padding: 10px 30px;
    border-radius: 50px;
    height: auto;
    color: #fff;
    font-weight: 600;
}

.connect_div button{
    background: #ED6622;
    padding: 5px 30px !important;
    border-radius: 50px !important;
    height: auto;
    color: #fff;
    font-weight: 600;


}



.connect_div{
    display: flex;
    align-items: center;
}

.logo{
    width: 75px;
}

.title{
    color: #fff;
    padding-left: 15px;
    font-size: 20px;
}

@media (max-width:468px) {
    .title{
        display: none;
    }
}