.chat_outer{
    border-radius: 20px;
border: 1px solid rgba(255, 255, 255, 0.40);
background: rgba(217, 217, 217, 0.00);
padding: 0 5px;
}

.chat_logo{
    width: 30px;
    height: auto;
    border-radius: 50%;
}

.top_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.70);
}

.address p, .date p{
font-size: 12px;
}



.message_container p{
    font-size: 14px;
}

.chat_outer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;

    margin-top: 12px;

}

.message_container{
    color: #fff;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 0px;
    
}

.top_content p{
    padding: 0;
    margin: 0;
}

.message_container p{
    padding: 0;
    margin: 0;
}

.chat_logo_container{
    width: 20%;
}

.chat_content{
    width: 80%;
    padding-left: 10px;
}