.card1{
    border-radius: 20px;
border: 1px solid #F62C2C;
background: rgba(217, 217, 217, 0.1);
box-shadow: 0px 0px 25px 0px rgba(246, 44, 44, 0.5);
width: 100%;
padding: 20px 10px;
}

.card2{
    border-radius: 20px;
    width: 100%;
padding: 20px 10px;
border: 1px solid #3DA3DC;
background: rgba(217, 217, 217, 0.10);
box-shadow: 0px 4px 25px 0px rgba(61, 163, 220, 0.5);
}

.cards_inner{
    padding: 10px;
}

.timer{
    font-weight: 600;
    font-size: 20px;
}

.card3{
    width: 100%;
padding: 20px 10px;
    border-radius: 20px;
border: 1px solid #FE7122;
background: rgba(217, 217, 217, 0.10);
box-shadow: 0px 0px 25px 0px rgba(254, 113, 34, 0.5);
}

.card4{
    border-radius: 20px;
border: 1px solid #18D162;
background: rgba(217, 217, 217, 0.10);
box-shadow: 0px 4px 25px 0px rgba(24, 209, 98, 0.5);
width: 100%;
padding: 20px 10px;
}


.card1 .cards_info, .card2 .cards_info, .card3 .cards_info, .card4 .cards_info{
font-size: 14px;
margin: 10px;
display: block;
}

.cards_info{
    color: #fff !important;
}


.wallet_button_bet{
    margin-top: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

}
.wallet_button_bet:hover{
   transform: scale(1.03);
}


.timer_container{
    margin: auto;
    background: rgba(255, 255, 255, 0.1);
    padding: 1px 0px;
    width: 300px;
    color: #fff;
    margin-bottom: 50px;
}


.cards_outer{
    display: flex;
    gap:30px;
    justify-content: space-between;
}

.card_div_outer{
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
}

.cards_title{
    font-size: 20px;

}

.card1 .cards_title{
    color: #F62C2C;
}
.card2 .cards_title{
    color: #3DA3DC;
}
.card3 .cards_title{
    color: #FE7122;
}
.card4 .cards_title{
    color: #18D162;
}

