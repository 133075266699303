.sidebar_outer{
    position: relative;
    width: 90%;
    margin:auto;
    transition: all 0.3s ease-in-out;
    color: #fff;


}
.button_container{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
    align-items: center;
}

.green{
    color: #31E890;
}


.wallet_send{
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.wallet_send:hover{
    transform: scale(1.03);
}

.sendMessage_container{
    position: sticky;
    bottom: 0;
    padding-bottom: 20px;
    right: 1.50;
    width: 100%;

    background-color: #121212;
}

.sendMessage_container input{
    width: 100%;
    margin-top: 20px;
    height: 40px;
    border-radius: 50px;
    padding-left: 15px;
border: 1px solid rgba(255, 255, 255, 0.10);
background: rgba(255, 255, 255, 0.10);
    color: #fff;
}